<template>
  <div>
    <v-container fill-height grid-list-xsm class="mx-auto justify-center">
      <v-card
        tile
        elevation="0"
        color="white"
        fill-height
        height="95vh"
        class="mx-auto text-center justify-center align-center"
      >
        <div class="pt-10 mx-auto justify-center align-center">
          <v-img
            src="@/assets/img/5191984.jpg"
            contain
            class="mb-8 mx-auto justify-center align-center"
            style="width: 300px; height: auto;"
          />
        </div>

        <div class="pb-5 text-center">
          <h1 class="mb-2">
            {{$t('delivery.lang_oopsPageNotFound')}}
          </h1>

          <span class="body-2 grey--text">
            {{$t('delivery.lang_thePageYouAreLookingForNotExit')}}
          </span>
        </div>

        <div>
          <v-btn
            elevation="0"
            color="accent darken-1"
            class="px-6 text-capitalize rounded-xl"
            @click="$router.push('/')"
          >
            {{$t('generic.lang_backToMenu')}}
          </v-btn>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "NotFoundComponent",
};
</script>
